import { RouteLocationNormalized } from "vue-router"
import { LocalStorageKey } from "@/constant/cache"
import { UN_STATUS_LOGIN_ROUTE, LANDING_COMBO_MUL_SPEC } from "@/constant/route"
import tsTrackingSdkInstance from "@/utils/tssdk"

async function checkSrcLink(uuid: string) {
  try {
    const { ok } = await CheckSrcId({ uuid })
    return ok
  } catch (error) {
    return false
  }
}

const isCheckSid = false

export const useSetTsSdkUtmCookie = async ({ _uuid, srcId }) => {
  const resultUtmCookieObj = {
    $source_sid: srcId,
    $source_uuid: _uuid
  }
  try {
    const { param } = await postLandingCustomLink({
      s_id: _uuid ? undefined : srcId,
      uuid: _uuid
    })
    for (const key in param) {
      if (key.startsWith("utm")) {
        resultUtmCookieObj[`$${key}`] = isArray(param[key]) ? param[key][0] : param[key]
      }
    }
  } catch (e) {
    return
  } finally {
    tsTrackingSdkInstance.coverUtmCookieValue(resultUtmCookieObj)
  }
}

// 判断是否有本地渠道记录，有的话带上参数
export const useSidParams = async (to: RouteLocationNormalized) => {
  const path = to.fullPath
  if (path.includes(unref(UN_STATUS_LOGIN_ROUTE[0]))) {
    return
  }
  if (process.client) {
    const { isStaticChannel, getChannel } = useExhibitionStaticParams()
    try {
      let srcId = isArray(to.query.sId) ? to.query.sId[0] : to.query.sId
      const _uuid = isArray(to.query._uuid) ? to.query._uuid[0] : to.query._uuid
      if (to?.path?.includes(unref(LANDING_COMBO_MUL_SPEC))) {
        srcId = process.env.Landing_Mul_Spec_SId
      }
      if (srcId) {
        // 这边写死
        if (isStaticChannel(srcId, _uuid)) {
          // 配置渠道和埋点的渠道名字 减少请求
          getChannel(srcId, _uuid)
          // TODO 重新赋予sdk埋点的 source_id source_uuid值
          await useSetTsSdkUtmCookie({ srcId, _uuid })
          return
        } else {
          const ok = await checkSrcLink(srcId)
          // 来源追踪 保存设置
          if (ok) {
            useReferrer2().value = srcId
            setLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P2, srcId)
            // TODO 重新赋予sdk埋点的 source_id source_uuid值
            await useSetTsSdkUtmCookie({ srcId, _uuid })
          } else {
            useReferrer2IsCorrect().value = false
          }
        }
      }
      if (!isStaticChannel(srcId, _uuid)) {
        const _srcId = useReferrer2().value || useReferrer().value
        const { setChannelGroupName, hasChannelNames } = useGetChannel()
        _srcId && !hasChannelNames.value && (await setChannelGroupName(_srcId))
      }
    } catch (e) {}
  }
}
