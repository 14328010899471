import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "~~/constant/cookies"

export enum FbqEvent {
  Stand = "track",
  Custom = "trackCustom"
}

class FbqPoint {
  static instance: FbqPoint = null
  static getInstance(): FbqPoint {
    this.instance = this.instance ? this.instance : new FbqPoint()
    return this.instance
  }
  setEvent(name: string, params: any, eventType: FbqEvent = FbqEvent.Custom) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      // 判断是否有唯一id，用完删除
      const eventID = getLocalStorage(LocalStorageKey.SERVER_PAYMENT_EVENT_ID)
      window?.fbq(eventType, name, params, eventID ? { eventID } : undefined)
    }
  }
}

export default FbqPoint.getInstance()
