import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "~~/constant/cookies"

enum TwEvent {
  Event = "event"
}

class TwPonit {
  static instance: TwPonit = null
  static getInstance(): TwPonit {
    this.instance = this.instance ? this.instance : new TwPonit()
    return this.instance
  }
  setEvent(name: string, params: any, eventType = TwEvent.Event) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      // 判断是否有唯一id，用完删除
      const event_id = getLocalStorage(LocalStorageKey.SERVER_PAYMENT_EVENT_ID)
      window?.twq(eventType, name, { ...params, conversion_id: event_id ? event_id : undefined })
    }
  }
}

export default TwPonit.getInstance()
