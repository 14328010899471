import { useUser } from "@/store/user"
import { setGlobalLanguage, Global_Language, ILocaleValue } from "@/constant/i18n-country"
import { SessStorageKey } from "~~/constant/cache"

export const useGlobalUserSwitchLanguage = async () => {
  const use_user = useUser()
  const language = use_user.userLanguage
  const { $_need_init_data } = useNuxtApp()
  let { ip_language } = ($_need_init_data || {}) as any

  // 中转页面不展示
  if (useIsSensitiveLogin(useRoute().path)) {
    return
  }

  if (getLocalStorage(SessStorageKey.IS_SHOW_AUTO_SWITCH_LANGUAGE, "sessionStorage")) {
    return
  }
  // 已登录状态/已设置语言：校验设置语言与URL语言一致性
  if (language && language !== useI18nCountry().value) {
    setLocalStorage(SessStorageKey.IS_SHOW_AUTO_SWITCH_LANGUAGE, 1, "sessionStorage")
    useCommonNormalDialog({
      zIndex: 2001,
      headTitle: useTranslateI18n("login.signUp.notice"),
      contentDesc: useTranslateI18n("profile.basicInformation.t1"),
      submitBtnText: useTranslateI18n("profile.confirm.ok"),
      cancelBtnText: useTranslateI18n("profile.confirm.cancel"),
      close() {},
      submitCallback({ closeLoading, closeDialog }) {
        closeDialog()
      },
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
        setGlobalLanguage(language)
      }
    })
  }
  // 未登录状态/未设置语言：校验用户IP与URL语言一致性
  if (!language && ip_language !== useI18nCountry().value) {
    // 避免服务端返回为空
    if (!ip_language) {
      ip_language = ILocaleValue.en
    }
    const lan_name = Global_Language.find((item) => item.value === ip_language).name
    setLocalStorage(SessStorageKey.IS_SHOW_AUTO_SWITCH_LANGUAGE, 1, "sessionStorage")
    // 加载其他语言包
    await useLocaleMessages(ip_language)
    useCommonNormalDialog({
      zIndex: 2001,
      headTitle: useTranslateI18n("login.signUp.notice", [], "", ip_language),
      contentDesc: useTranslateI18n("profile.basicInformation.t2", [lan_name], "", ip_language),
      submitBtnText: useTranslateI18n("profile.confirm.ok", [], "", ip_language),
      cancelBtnText: useTranslateI18n("profile.confirm.cancel"),
      close() {},
      submitCallback({ closeLoading, closeDialog }) {
        setGlobalLanguage(ip_language || "en")
        closeDialog()
      },
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
      }
    })
  }
}
