import { useRefreshToken } from "@/composables/useRefreshToken"
import { TOKEN_ROUTER } from "@/constant/permission/index"
import { useUser } from "@/store/user"
import { TOKEN_KEY } from "@/constant/cookies"
import { useShowPaymentStatusDialog } from "@/composables/pages/payment/index"
import { useSetReferrer } from "@/composables/store/referrer"
import { useClientBridgeRouteGuard, useClientPointOpen } from "~~/composables/guard/useClientBridgeGuard"
import { registryMixpanel, registryMixpanelPageView } from "@/composables/useMixpanel"
import { registryTsSdk } from "@/composables/useTsSdk"
import { useIm } from "@/composables/useIm"
import { useLandingPageStaticPath } from "@/composables/useWhiteList"
import { DOWNLOAD_HELP_PAGE_PATH, DOWNLOAD_PAGE_PATH, HOME } from "@/constant/route"
import useNavbar from "@/constant/useNavbar"
import { useAllActivitys } from "@/constant/useAllActivitys"

let clientAndLoginRequest = false

// 权限拦截
async function permissionIntercept({ path }, clientTk: string, isInlineClient: boolean) {
  const token = useCookie(TOKEN_KEY)
  const use_user = useUser()
  if ((!use_user.isLogin && token.value) || (!use_user.isLogin && clientTk && process.client)) {
    // 如果是内嵌进来的token
    if (clientTk) {
      // TODO 使用useCookie设置token再evoto客户端内嵌,cookie在浏览器设置事异步的，故使用原生直接设置，避免请求问题
      // setCookie(CookiesKey.TOKEN_KEY, clientTk)
      token.value = clientTk
    }
    // 获取简单的用户信息
    const isSimple = useLandingPageStaticPath(path)
    const fl = await use_user.setAllUserInfo(isSimple)
    if (!fl) {
      return
    }
  }

  // 如果是已登录情况下
  if (process.client && !clientAndLoginRequest) {
    // 获取用户信息 判断当前是否re_active过,是否需要显示payment_status弹窗, 刷新路由更新用户信息
    use_user.isLogin && !isInlineClient && (await useShowPaymentStatusDialog(true))
    // 判断是否刷新token
    use_user.isLogin && !isInlineClient && (await useRefreshToken())
    // 客户端内嵌开启埋点
    isInlineClient && useClientPointOpen()

    clientAndLoginRequest = true
  }

  // 如果没登录没权限 暂时跳首页
  if (!token.value && TOKEN_ROUTER().includes(path)) {
    return useLocalePath()("/")
  }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  // useDefaultSEO(to.path)

  // check browser version
  useBrowserValidatorGuard()

  const { tk, isInlineClient } = useClientBridgeRouteGuard(to)

  const { setHallowOpenValue } = useAllActivitys()

  const redirect = await permissionIntercept(to, tk, isInlineClient)

  await setHallowOpenValue()

  if (process.client) {
    // 是否开启 im
    useIm(to)
    // 设置不同navbar 背景色
    useNavbar(to.path)
    // 设置广告来源storage
    useSetReferrer(to.query)
    // 设置头部url参数带上sId 渠道来源
    await useSidParams(to)
    // 注册mixpanel
    await registryMixpanel()
    //自研
    await registryTsSdk()
    // // 派发mixpanel pv ，内嵌不派发官网的pv
    // !isInlineClient && registryMixpanelPageView(to)
    // 根据页面配置设置可切换语言
    useLanguage(to.meta)
  }
  if (unref(redirect)) {
    return navigateTo(unref(redirect))
  }
})
