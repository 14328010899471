export enum commonApi {
  GET_ENUM_LIST = "/v1/app/enum/get_list",
  LOGIN_API = "/v1/app/login",
  REGISTRY_API = "/v1/app/register",
  SEND_VERIFY_CODE = "/v2/app/send_verify_code",
  CHECK_VERIFY_CODE = "/v1/app/check_verify_code",
  GET_OAUTH_URL = "/v1/app/oauth_url",
  OAUTH_URL = "/v1/app/oauth",
  GET_OAUTH_STATE = "/v1/app/oauth_status",
  GET_LOGIN_INFO = "/v1/app/login_info",
  FORGET_PASSWORD = "/v1/app/forget_password",
  CHANGE_PASSWORD = "/v1/api/user/change_password",
  GET_BASIC_INFO = "/v1/api/user/basic_info",
  GET_BASIC_INFO_V2 = "/v1/api/user/basic_info_web",
  SOCIAL_DISCONNECT = "/v1/api/user/social/disconnect",
  CONSULTATION_ADD = "/v1/app/consultation/add",
  MODIFY_NAME = "/v1/api/user/modify_name",
  GET_CLIENT_COUNTRY = "/v1/app/get_client_country",
  CHECK_TOKEN = "/v1/app/check_token", // 检查token接口
  REFRESH_TOKEN = "/v1/app/refresh_token", // 刷新token
  GET_FB_GROUP_URL = "/v1/web/get_fb_group_url", // 获取fb群组
  CHECK_INTERFACE_STATE = "/v1/app/interface_rate",
  GET_COUNTRY_IP = "/v1/app/get_country_info", // 获取国家ip
  GET_CONTACT_GROUP_URL = "/v1/web/get_contact_group_url",
  GET_CONTACT_GROUP_URL_V2 = "/v1/web/get_contact_group_url_v2",
  GET_CONTACT_CODE = "/v1/api/trade/contact_code",
  GET_JUMP_TOKEN = "/v1/api/user/jump_token",
  GET_CHANNEL_NAME = "/v1/web/get_name_by_uuid",
  POST_NC = "/v1/app/nc",
  POST_VC = "/v1/app/vc",
  GET_ANNNOUNCEMENT = "/v1/web/get_announcement",
  GET_LANGUAGE_IM = "/v1/app/get_im",
  SET_CHANNEL_CODE = `/v1/api/user/channel_code`,
  UPDATE_MAIL_SUB = "/v1/api/user/update_mail_sub",
  REF_PSD = "/v1/api/user/reg_password",
  UNBIND_USER = "/v1/api/user/identifier/unbind",
  BIND_USER = "/v1/api/user/identifier/bind",
  UPDATE_EDM = "/v1/api/user/edm_email/update",
  VERIFY_EDM = "/v1/api/user/edm_email/verify",
  CHANGE_EMAIL = "/v1/api/user/identifier/change",
  GET_LIVE_CHAT = "/v1/api/user/live_chat",
  VALIDATE_POINT = "/v1/web/data_validation",
  GET_PRIVACY = "/v1/web/policy",
  MODIFY_COMPANY_NAME = "/v1/api/user/modify_admin_name",
  POST_SET_LOCAL = "/v1/api/user/set_locale",
  POST_SET_AUTH_METHODS = "/v1/api/user/identifier/set",
  POST_AUTH_LIST = "/v1/web/identifier/get",
  POST_SET_RECOVER_EMAIL = "/v1/api/user/identifier/set_recover_email",
  POST_SEND_EMAIL = "/v1/web/identifier/send",
  POST_CODE_EMAIL = "/v1/web/identifier/get_email",
  POST_RESET_PASSWORD = "/v1/web/identifier/reset",
  POST_CHECK_RECOVER_EMAIL = "/v1/api/user/identifier/check_recover_email",
  POST_SEND_CODE = "/v1/api/user/identifier/send_recover_email",
  POST_AUTH_TOKEN = "/v1/app/auth_by_token"
}

export enum EnumType {
  BusinessType = 9,
  Source = 10,
  Usage = 35
}

export enum InterfaceStateEnum {
  Normal = 0,
  Limit = 3006,
  Forbidden = 3007
}
