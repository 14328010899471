import http from "@/http/index"
import { paymentApi } from "@/constant/api/payment"

import type { IPaymentPlanResult, IAdditionPackageList, ICountryList, IPassThrough } from "@/types/pages/payment"

export enum PaymentMethod {
  Card = 1,
  PayPal
}

export interface IPaymentLogData {
  checkout_id?: string
  email?: string
  payment_method?: PaymentMethod
  paddle_id?: any
  sale_gross?: any
  sale_currency?: string
  error?: string
  custom_link_uuid?: string
  trade_no?: string
}

// 获取所以套餐订阅计划
export const getPaymentPlan = <T = IPaymentPlanResult>() => {
  return http.get<T>(paymentApi.Payment_Plan)
}

// 获取当前可购买补充包
export const getAdditionPackage = <T = IAdditionPackageList>() => {
  return http.get<T>(paymentApi.Addition_Package)
}

// 获取用户信息
export const getPassThrough = <T = IPassThrough>(options: {
  plan_id: number
  uuid?: string
  source?: any
  is_sec_kill_activity?: boolean
  gift_card_receiver?: string
  sender_name?: string
  purchase_uuid?: string
  purchase_source?: string
  ads_conversion?: string
}) => {
  return http.post<T>(paymentApi.Payment_PassThrough, {
    ...options,
    ...useFprCookie()
  })
}
// 不登录获取用户信息

export const getNoLoginPassThrough = <T = any>(params: {
  uuid: string
  plan_id: any
  purchase_uuid?: string
  purchase_source?: string
  ads_conversion?: string
}) => {
  return http.get<T>(paymentApi.No_Login_PassThrough, params)
}

// 订购流量包
export const postAddDatePackage = (args: { product_id: number }) => {
  return http.post(paymentApi.Payment_AddDatePackage, args)
}

// 更新套餐 降级、升级
export const postUpdatePlan = (args: { product_id?: number; cancel_downgraded?: boolean }) => {
  return http.post(paymentApi.Payment_UpdatePlan, args)
}

// 确认变更套餐
export const postConfirmPlan = (args: { product_id: number }) => {
  return http.post(paymentApi.Payment_ConfirmPlan, args)
}

// 确认购买流量包
export const postConfirmBugPackage = (args: { product_id: number }) => {
  return http.post(paymentApi.Payment_ConfirmBuyPackage, args)
}

// 取消订阅
export const postCancelSubscription = (args: { recover?: boolean; reason_id?: number[]; reason_content?: string }) => {
  return http.post(paymentApi.Payment_Cancel, args)
}

// 获取国家列表
export const getCountryList = <T = ICountryList>() => {
  return http.get<T>(paymentApi.GET_COUNTRY_LIST)
}

// 阅读付款状态
export const postReadPaymentStatus = () => {
  return http.post(paymentApi.Read_Payment_Status)
}

// fb上报广告转化api
export const postToConFb = (data) => {
  return http.post(
    `https://graph.facebook.com/v14.0/831321388090724/events?access_token=EAAaANFjW5WYBAMoFrRvy0CcJWrPkiZCP7zfVsh6SZB3dVMZAbRJgeuBSi9iw5AipEfPBuSYjrPkgbn9GQKNFB09ZBw6ZAf5IcMJH5lcdbB8kdb4sAOy6AmSQVYR39RFX2syddfXnHZBHc8fHppExxD4jPdfPZCdbqZCKRrgJz6PWX8EbbjbaKKcCZAobAZBbAuA5cZD`,
    data
  )
}

export const postPaymentLog = (data: IPaymentLogData) => {
  return http.post(paymentApi.PAYMENT_LOG, data)
}

// 获取兑换码页面的paddle的passthrough
export const getRedeemPassthrough = <T = IPassThrough>(options: {
  plan_id: number
  uuid?: string
  source?: any
  is_sec_kill_activity?: boolean
  gift_card_receiver?: string
  sender_name?: string
  purchase_uuid?: string
  purchase_source?: string
  ads_conversion?: string
}) => {
  return http.post<T>(paymentApi.PostRedeemPaddlePassThrough, options)
}
