import mixpanelInstance from "@/utils/mixpanel"
import { mpEventName, ClientMpEventName } from "@/constant/mp"
import { ICountryDict } from "@/constant/i18n-country"
import { ProductType } from "@/composables/api/common"
import { LANDING_COMBO_PAGE, PAYMENT_COMBO_ROUTE } from "@/constant/route"
import { ICommonProperty } from "@/composables/utils/mp/common"
import { useUser } from "~~/store/user"

const setEvent = mixpanelInstance.setEvent

export const EVOTO_WEBSITE_TO_SERVER_PARAMS = () => {
  return {
    evoto_DT_purchasePage: "evoto_DT_purchasePage",
    [unref(PAYMENT_COMBO_ROUTE)]: "evoto_website_pricing",
    [unref(LANDING_COMBO_PAGE)]: "evoto_purchase_landing"
  }
}

export enum IWebsitePricingSource {
  evoto_website = "evoto_website",
  evoto_purchase_landing = "evoto_purchase_landing"
}

export enum IPricingType {
  NewPurchase = "newPurchase",
  Upgrade = "upgrade",
  Downgrade = "downgrade",
  CurrentPlan = "currentPlan",
  ReActivate = "reActivate",
  Custom = "custom",
  Package = "package",
  RevertCancelSub = "revertCancelSubscription",
  RevertDowngrade = "revertDowngrade",
  VietnamPackage = "vietnamPackage", // 越南套餐
  thailandPackage = "thailandPackage", // 泰国套餐
  malaysiaPackage = `malaysiaPackage`, // 马来套餐
  indonesiaPackage = `indonesiaPackage`, // 印尼套餐
  philippinesPackage = `philippinesPackage`, // 菲律宾套餐
  japanPackage = `japanPackage`, // 日本套餐
  basicPackage = "basicPackage" // 基础套餐
}

export const ponitTypeDict = () => {
  return {
    [ProductType.OTHER]: IPricingType.basicPackage,
    [ProductType.VN_PKG]: IPricingType.VietnamPackage,
    [ProductType.ID_PKG]: IPricingType.indonesiaPackage,
    [ProductType.MY_PKG]: IPricingType.malaysiaPackage,
    [ProductType.PH_PKG]: IPricingType.philippinesPackage,
    [ProductType.TH_PKG]: IPricingType.thailandPackage,
    [ProductType.JP_PKG]: IPricingType.japanPackage,
    [ICountryDict.VN]: IPricingType.VietnamPackage,
    [ICountryDict.ID]: IPricingType.indonesiaPackage,
    [ICountryDict.MY]: IPricingType.malaysiaPackage,
    [ICountryDict.PH]: IPricingType.philippinesPackage,
    [ICountryDict.TH]: IPricingType.thailandPackage,
    [ICountryDict.JP]: IPricingType.japanPackage
  }
}

export enum ISub {
  Monthly = "monthly",
  Yearly = "yearly"
}

export const CompareGradList = {
  UPGRADE: "upgrade", // c升级
  CURRENT: "current", // 当前
  DOWNGRADE: "downgrade", // 降级
  RE_ACTIVE: "re_active", // 取消订阅账单已经发来，重新订阅
  IS_DOWNGRADED: "is_downgraded",
  IS_CANCELED: "is_canceled",
  CUSTOM: "custom"
}

export const IPricingTypeDict = {
  [CompareGradList.UPGRADE]: IPricingType.Upgrade,
  [CompareGradList.DOWNGRADE]: IPricingType.Downgrade,
  [CompareGradList.CURRENT]: IPricingType.CurrentPlan,
  [CompareGradList.RE_ACTIVE]: IPricingType.ReActivate,
  [CompareGradList.CUSTOM]: IPricingType.Custom
}

interface IPaymentWebsiteBuyButton {
  source: IWebsitePricingSource
  pricingModel: IPricingType
}

interface IMpPaymentWebsitePricing {
  source: IPricingType
}

interface IMpPaymentWebsitePurchase {
  type: IPricingType
  result: "success" | "failure"
}
interface IMpPaymentWebsitePurchasePage {
  type: IPricingType
  subscription?: ISub
}

export function usePaymentMP() {
  const _pointMpPaymentPurchaseSuccess = () => {
    setEvent(mpEventName.PaymentPurchaseSuccess)
  }

  const _pointDTPAymentWebsitePricing = () => {
    setEvent(ClientMpEventName.evoto_DT_pricing)
  }

  const _pointDTPAymentWebsitePurchasePage = (params: Pick<IPaymentWebsiteBuyButton, "pricingModel">) => {
    setEvent(ClientMpEventName.evoto_DT_purchasePage, params)
  }

  const _pointMpOfficialPricingButtonClick = (params: ICommonProperty & { buttonName: any }) => {
    setEvent(mpEventName.PaymentWebsiteOfficialPricing, {}, params)
  }

  const _pointOfficialPricingBuyButtonClick = (
    params: ICommonProperty & { packageId: any; packageName: any; packagePrice: any; packageCredit: any }
  ) => {
    setEvent(
      mpEventName.website_officialPricingBuyButtonClick,
      {},
      {
        ...params,
        buttonName: "buyNow",
        featureModule: "officialPricing",
        featureName: "clickPricingBuyButton",
        pageTitle: "pricingPage"
      }
    )
  }

  // 内嵌页面价格按钮点击
  const _pointDTOfficialPricingBuyButtonClick = (
    params: ICommonProperty & { packageId: any; packageName: any; packagePrice: any; packageCredit: any }
  ) => {
    setEvent(
      mpEventName.DT_h5PricingBuyButtonClick,
      {},
      {
        ...params,
        featureModule: "h5Pricing",
        featureName: "clickPricingBuyButton"
      }
    )
  }

  // 内嵌页面 contact us点击
  const _pointDTOfficialButtonClick = () => {
    const use_user = useUser()
    setEvent(
      mpEventName.DT_h5PricingButtonClick,
      {},
      {
        pricingModel: ponitTypeDict()[use_user.productType],
        featureModule: "h5Pricing",
        featureName: "clickContactSales"
      }
    )
  }

  const _pointOfficialH5BuyButtonClick = (
    params: ICommonProperty & { packageId: any; packageName: any; packagePrice: any; packageCredit: any }
  ) => {
    setEvent(
      mpEventName.h5_buyButtonClick,
      {},
      {
        ...params,
        featureModule: "h5Pricing",
        featureName: "clickH5BuyButton",
        buttonName: "buyNow",
        pageTitle: "h5PurchasePage"
      }
    )
  }

  return {
    _pointMpPaymentPurchaseSuccess,
    // _pointMpPaymentWebsitePricing,
    _pointDTPAymentWebsitePricing,
    _pointDTPAymentWebsitePurchasePage,
    _pointMpOfficialPricingButtonClick,
    _pointOfficialPricingBuyButtonClick,
    _pointOfficialH5BuyButtonClick,
    _pointDTOfficialPricingBuyButtonClick,
    _pointDTOfficialButtonClick
  }
}
