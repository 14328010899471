import { IClickType } from "@/constant/index"

export enum navBarLinkKey {
  Features = "_Features",
  Community = "_Community",
  Pricing = "_Pricing",
  Download = "_Download",
  Blog = "_Blog",
  Forum = "_Forum"
}

export interface INavBarLinkObj {
  name?: string
  path?: string
  key?: string
  type: IClickType
  slotName?: string
  children?: INavBarLinkObj[]
  [x: string]: any
}

export type INavBarLinkData = INavBarLinkObj[]
