import { DownloadPlatform, downloadPlatformAppType, downloadPlatformInstallerAppType } from "~~/constant"
import { useDownloadStore } from "~~/store/download"
import { useDownloadThanksDialog } from "./components/dialog/downloadThanks/useDownloadThanksDialog"
import { AdDownloadChannel } from "~~/constant/referrer"
import { useReferrer } from "@/composables/store/referrer"
import { getDownloadUrl, postDownloadCount } from "./api/download"
import { JoinFacebookSource } from "@/constant/mp"
import { useUser } from "@/store/user"
import {
  DOWNLOAD_GUIDE_PAGE_PATH,
  DOWNLOAD_PAGE_PATH,
  LANDING_COMBO_BF,
  LANDING_COMBO_MUL,
  LANDING_SHOT_C,
  DOWNLOAD_HELP_PAGE_PATH
} from "@/constant/route"
import { LocalStorageKey } from "@/constant/cache"
import { ThanksDialogTextType } from "@/composables/api/common"
import { DOWNLOAD_INFO_KEY } from "~~/constant/pages/download"
import { useStorage } from "@vueuse/core"
import { scopeEnum } from "~~/constant/diversion"

type UseDownloadOptions = {
  source?: JoinFacebookSource
}

export function useDownload(options: UseDownloadOptions = {}) {
  const { source: s = JoinFacebookSource.Website } = options
  const loading = ref(false)
  const hasDownloaded = ref(false)
  const downloadStore = useDownloadStore()
  const { fetchSoftwareList, getChannelId } = downloadStore
  const { getSoftwareList, getSoftwareStableList, isTrialSoftwareType } = storeToRefs(downloadStore)
  const { isMobile, isSafari } = useDevice()
  const { path } = useRoute()
  const isManage = useLandingPageStaticPath(path)
  !isManage && import.meta.client && fetchSoftwareList(true)
  async function download(platform: DownloadPlatform, isJumpFlag = false, email?: string, label?: scopeEnum) {
    // 广告下载点击事件
    useAdsDownloadClick()
    const fullPath = useRoute().fullPath
    const use_user = useUser()
    const useEmail = email || use_user.userInfo?.email
    if ((!platform || isMobile.value) && !isJumpFlag) {
      isMobile.value &&
        navigateTo({
          path: unref(DOWNLOAD_HELP_PAGE_PATH),
          query: useEmail
            ? {
                e: useEmail
              }
            : {}
        })
      return
    }
    if ((!platform || isMobile.value) && isJumpFlag) {
      return
    }
    loading.value = true
    const appType = downloadPlatformInstallerAppType[downloadPlatformAppType[platform]]
    await fetchSoftwareList()
    // eslint-disable-next-line prefer-const
    // 下载页面根据software判断，其他页面下载稳定版本
    const isDownloadPage = fullPath.startsWith(unref(DOWNLOAD_PAGE_PATH))
    const { package_url, app_type, version } = (
      unref(isDownloadPage ? getSoftwareList : getSoftwareStableList) || []
    ).find((item) => item?.app_type === appType)

    if (!package_url) {
      return
    }

    if (!hasDownloaded.value && !isJumpFlag) {
      const { style: type, addr: url } = await getContactGroupUrlV2(
        label ? label : scopeEnum.downloadFreeTrail,
        useEmail,
        useReferrer2().value || useReferrer().value
      )
      // useDownloadThanksDialog({
      //   textType: ThanksDialogTextType.Type1,
      //   pointPageTitle: "afterDownload",
      //   source: s,
      //   url,
      //   type,
      //   title: {
      //     t1: useTranslateI18n("download.thanks.title"),
      //     t2: useTranslateI18n("download.thanks.desc")
      //   }
      // })
      const params = {
        textType: ThanksDialogTextType.Type1,
        pointPageTitle: "afterDownload",
        source: s,
        url,
        type
      }
      const storage = useStorage(DOWNLOAD_INFO_KEY, params)
      storage.value = params
      hasDownloaded.value = true
    }
    // 渠道包
    const referrer = useReferrer() // 渠道地址
    const referrer2 = useReferrer2()
    // 渠道包下载根据后台配置的
    if (referrer.value || referrer2.value) {
      const resultSource = referrer2.value ? referrer2.value : referrer.value ? AdDownloadChannel[referrer.value] : ""
      let id
      // 下载页面根据下载页面的来
      if (isDownloadPage) {
        id = unref(getSoftwareList).find((item) => item.app_type === appType).id
      } else {
        id = getChannelId(appType)
      }
      const { download_url, file_name } = await getDownloadUrl(resultSource as string, {
        id
      })
      location.href = `${download_url}${file_name ? `filename=${file_name}` : ""}`
      loading.value = false
      // 判断是否有缓存渠道
    } else {
      location.href = package_url
      loading.value = false
    }
    // 下载的是抢先版本的包  计数
    if (isTrialSoftwareType.value && isDownloadPage) {
      setDownloadCount(version, app_type)
    }
    // 兼容safari
    if (isSafari.value) {
      await delay(2000)
    }
    navigateTo({
      path: unref(DOWNLOAD_GUIDE_PAGE_PATH)
    })
  }
  const downloadDebounce = useThrottleFn(download, 1000)

  return {
    loading,
    download: downloadDebounce
  }
}

function setDownloadCount(version: string, app_type: any) {
  function getDownloadCountLocal() {
    return JSON.parse(getLocalStorage(LocalStorageKey.Download) || "{}")
  }

  function setDownloadCountLocal() {
    versionControl[version] = 1
    setLocalStorage(LocalStorageKey.Download, JSON.stringify(versionControl))
    // 记录次数
    postDownloadCount(app_type)
  }

  const versionControl = getDownloadCountLocal()

  const versionKeys = Object.keys(versionControl)

  if (!versionKeys.length) {
    setDownloadCountLocal()
  } else {
    if (!versionKeys.find((v) => v === version)) {
      setDownloadCountLocal()
    }
  }
}
