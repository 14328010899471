import { INavBarLinkData } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import { navBarLinkKey } from "@/types/components/common/navbar"
import {
  BACKGROUND_ADJUSTMENTS,
  BATCH_EDITS,
  COLOR_AND_TONE,
  DOWNLOAD_PAGE_PATH,
  EXCLUSIVE_PRESETS,
  HELP_PAGE_PATH,
  PAYMENT_COMBO_ROUTE,
  CHANGE_SKIN_COLOR,
  BACKGROUND_REMOVAL,
  HAIR_COLOR_CHANGER,
  PORTRAIT_RETOUCHING,
  GLARE_REMOVAL,
  BLEMISH_REMOVAL,
  HEADSHOT,
  SMILE_GENERATION,
  CROP_IMAGE,
  START_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  PAYMENT_COMBO_PROCEED_ROUTE,
  BLOG_PAGE,
  IPAD_PAGE
} from "./route"

export const navbarLinkDict: () => INavBarLinkData = () => {
  return [
    {
      type: IClickType.Skip,
      path: unref(IPAD_PAGE)
    },
    {
      type: IClickType.Hover,
      key: navBarLinkKey.Features,
      children: [
        {
          path: unref(CHANGE_SKIN_COLOR),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(PORTRAIT_RETOUCHING),
          type: IClickType.Skip
        },
        {
          path: unref(HAIR_COLOR_CHANGER),
          type: IClickType.Skip
        },
        {
          path: unref(GLARE_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(BLEMISH_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(HEADSHOT),
          type: IClickType.Skip
        },
        {
          path: unref(SMILE_GENERATION),
          type: IClickType.Skip
        },
        {
          path: unref(CROP_IMAGE),
          type: IClickType.Skip
        },
        {
          path: unref(BATCH_EDITS),
          type: IClickType.Skip
        },
        {
          path: unref(COLOR_AND_TONE),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_ADJUSTMENTS),
          type: IClickType.Skip
        },
        {
          path: unref(EXCLUSIVE_PRESETS),
          type: IClickType.Skip
        }
      ]
    },
    {
      type: IClickType.Hover,
      key: navBarLinkKey.Community,
      children: [
        {
          type: IClickType.Click,
          key: navBarLinkKey.Forum
        },
        {
          path: unref(START_PAGE_PATH),
          type: IClickType.Skip
        },
        {
          path: unref(BLOG_PAGE),
          type: IClickType.Click,
          key: navBarLinkKey.Blog
        }
      ]
    },
    {
      path: unref(PAYMENT_PAGE_PATH),
      type: IClickType.Click,
      key: navBarLinkKey.Pricing,
      slotName: "payment"
    },
    {
      path: unref(HELP_PAGE_PATH),
      type: IClickType.Skip
    },
    {
      path: unref(DOWNLOAD_PAGE_PATH),
      key: navBarLinkKey.Download,
      type: IClickType.Skip
    }
  ]
}

export const navbarLink: (...args: any[]) => INavBarLinkData = () => {
  const list = navbarLinkDict()

  const result = list.map((item, i) => {
    return {
      name: useTranslateI18n(`common.navbarLink[${i}].name`),
      path: item.path,
      type: item.type,
      key: item.key,
      slotName: item.slotName,
      children:
        item.children &&
        item.children.map((cItem, index) => {
          return {
            name: useTranslateI18n(`common.navbarLink[${i}].children[${index}].name`),
            path: cItem.path,
            type: cItem.type,
            key: cItem.key
          }
        })
    }
  })
  return result
}
// 不同设备的navabr的style 字典  超过1920取1920的样式
export const deviceDict = {
  1920: {
    startPaddingTop: 48,
    endPaddingTop: 24
  },
  600: {
    startPaddingTop: 16,
    endPaddingTop: 8
  }
}

export const NAV_ID = "nav-id"

export const newHomeNavbarLinkDict: () => INavBarLinkData = () => {
  return [
    {
      type: IClickType.Hover,
      children: [
        {
          path: unref(CHANGE_SKIN_COLOR),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(PORTRAIT_RETOUCHING),
          type: IClickType.Skip
        },
        {
          path: unref(HAIR_COLOR_CHANGER),
          type: IClickType.Skip
        },
        {
          path: unref(GLARE_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(BLEMISH_REMOVAL),
          type: IClickType.Skip
        },
        {
          path: unref(SMILE_GENERATION),
          type: IClickType.Skip
        },
        {
          path: unref(CROP_IMAGE),
          type: IClickType.Skip
        },
        {
          path: unref(BATCH_EDITS),
          type: IClickType.Skip
        },
        {
          path: unref(COLOR_AND_TONE),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_ADJUSTMENTS),
          type: IClickType.Skip
        },
        {
          path: unref(EXCLUSIVE_PRESETS),
          type: IClickType.Skip
        }
      ]
    },
    {
      type: IClickType.Hover,
      children: [
        {
          type: IClickType.Click,
          key: navBarLinkKey.Forum
        },
        {
          path: unref(START_PAGE_PATH),
          type: IClickType.Skip
        },
        {
          path: unref(BLOG_PAGE),
          type: IClickType.Click,
          key: navBarLinkKey.Blog
        }
      ]
    },
    {
      path: unref(PAYMENT_COMBO_ROUTE),
      type: IClickType.Click,
      key: navBarLinkKey.Pricing
    },
    {
      path: unref(HELP_PAGE_PATH),
      type: IClickType.Skip
    }
  ]
}

export const newHomeNavbarLink: (...args: any[]) => INavBarLinkData = () => {
  return newHomeNavbarLinkDict().map((item, i) => {
    return {
      name: useTranslateI18n(`common.navbarLink[${i}].name`),
      path: item.path,
      type: item.type,
      key: item.key,
      children:
        item.children &&
        item.children.map((cItem, index) => {
          return {
            name: useTranslateI18n(`common.navbarLink[${i}].children[${index}].name`),
            path: cItem.path,
            type: cItem.type,
            key: cItem.key
          }
        })
    }
  })
}

export enum INavbarTheme {
  Dark = "dark",
  White = "white"
}
