import { ITokenInfo, SubStatus, LowId } from "@/composables/api/common"
import { TOKEN_KEY } from "@/constant/cookies"
import { getBasicInfoApi, IBasicInfoScope } from "@/composables/api/common"
import { LocalStorageKey } from "@/constant/cache"
import { registryMixpanel } from "@/composables/useMixpanel"
import { registryTsSdk, logoutUserAndOrg } from "@/composables/useTsSdk"
import { ProfileBillingSchemeCom, ProfileBillingScheme } from "@/constant/pages/profile"
import { VERIFY_TYPE } from "@/constant/login"
import { HOME } from "~/constant/route"
import { clearCookie } from "@/utils/utils"
import { COOKIE_EXPIRES, EVOTO_TOP_DOMAIN } from "~/constant/config"

type IUserInfo = {
  info: ITokenInfo["user_info"]
  token: string
  reload?: boolean
  redirectUrl?: string
}

export const useUser = defineStore({
  id: "use_user",
  state: () => {
    return {
      mail_sub_type: [], //  邮件订阅的包含类型
      has_qualification: false as boolean,
      login_tag: null as number,
      userInfo: {} as ITokenInfo["user_info"],
      subscription_info: {} as ITokenInfo["subscription_info"],
      subscription_info_v2: {} as ITokenInfo["subscription_info_v2"],
      social_list: [] as ITokenInfo["social_list"],
      showLoginDialogWrapper: false, // 显示登录框外层
      showLoginDialog: false, // 显示登录框
      common_uuid: "",
      platform_id: 1,
      profileBillingSchemeCom: ProfileBillingSchemeCom[ProfileBillingScheme.Sub] // 默认订阅方案组件
    }
  },
  actions: {
    // 判断是否是客户端跳转过来登录
    isAppLogin() {
      return !!getLocalStorage(LocalStorageKey.SignInApp)
    },
    setTk(tk) {
      const tokenExpires = dateUtil().add(COOKIE_EXPIRES, "day").toDate()
      // 单点登录会将token放到顶域cookie中
      const token = useCookie(TOKEN_KEY, {
        domain: !process.env.IS_LOCAL ? EVOTO_TOP_DOMAIN : undefined,
        expires: tokenExpires
      })
      token.value = tk
      return token.value
    },
    async setUserInfo(userInfo: IUserInfo) {
      const { info, token, reload = true, redirectUrl = "" } = userInfo
      this.setTk(token)
      this.userInfo = info
      //TODO 重新设置新的mixpanel的用户id，避免用户在登录成功接口上报上一个用户id
      !reload && resetMixpanel()
      !reload && (await registryMixpanel(true))
      !reload && (await registryTsSdk(true))
      if (redirectUrl) {
        redirectUrl && (location.href = redirectUrl)
        return
      }
      if (reload) {
        const timeId = setTimeout(() => {
          location.reload()
          clearTimeout(timeId)
        }, 10)
      }
    },
    async clearUserInfo(reload = true) {
      this.setTk(null)
      if (import.meta.client) {
        clearCookie(TOKEN_KEY)
      }
      this.$reset()
      resetMixpanel()
      //自研
      logoutUserAndOrg()
      removeLocalStorage(LocalStorageKey.SignInApp)
      await nextTick(() => {})
      if (reload) {
        const timeId = setTimeout(() => {
          location.href = unref(HOME)
          clearTimeout(timeId)
        }, 10)
      }
    },
    // 根据token更新所有用户信息
    async setAllUserInfo(isSimple = false, isScope: IBasicInfoScope = null) {
      // TODO cdn请求静态资源会进来在服务端批量并发调用请求用户信息接口
      if (useRoute().fullPath.includes("/_nuxt/") && process.server) return
      if (process.server) {
        console.log("getBasicInfo log", useRoute().fullPath)
      }
      const scope = isScope ? { scope: isScope } : {}
      const simple = isSimple ? { simple: 1 } : {}
      try {
        const data = await getBasicInfoApi({ ...simple, ...scope })
        console.log(`data`, data)
        this.login_tag = data.login_tag
        this.userInfo = data.user_info || {}
        this.subscription_info = data.subscription_info || {}
        this.subscription_info_v2 = data.subscription_info_v2 || {}
        this.social_list = data.social_list || []
        this.has_qualification = data.has_qualification
        this.mail_sub_type = data.mail_sub_type
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    // 显示登陆框
    showLoginDialogAction() {
      // this.showLoginDialogWrapper = flag
      // this.showLoginDialog = flag

      const { useSignInOrSignUpDialog } = useLoginAndRegFlow()
      useSignInOrSignUpDialog()
    },
    // 显示编辑密码
    async showLoginEditPsd() {
      const { useEditPsdEnterEmailDialog, useVerifySelectDialog } = useLoginAndRegFlow()

      if (this.isBindPhone && this.isBindEmail) {
        // 弹窗筛选
        const value = (await useVerifySelectDialog()) as VERIFY_TYPE
        useEditPsdEnterEmailDialog(value)
        return
      }

      useEditPsdEnterEmailDialog(this.isBindPhone ? VERIFY_TYPE.phone : VERIFY_TYPE.email)
    },
    //显示fb 的邮箱输入
    showFbEmailDialog() {
      const { useFbEnterEmailDialog } = useLoginAndRegFlow()
      useFbEnterEmailDialog()
    },
    // 显示注册成功后的密码输入
    showRegPsdEnterDialog() {
      const { useSignUpWithPsdDialog } = useLoginAndRegFlow()
      useSignUpWithPsdDialog()
    },
    // 显示注册成功后的哪个弹窗-》邮箱和手机号不同
    showSignUpSuccessDialog() {
      const { useOtherSignupEnterProfileDialog } = useLoginAndRegFlow()
      if (
        getLocalStorage(LocalStorageKey.OTHER_SIGNUP_DIALOG) &&
        !getLocalStorage(LocalStorageKey.PAYMENT_PRODUCT_ID)
      ) {
        useOtherSignupEnterProfileDialog()
        removeLocalStorage(LocalStorageKey.OTHER_SIGNUP_DIALOG)
      }
    }
  },
  getters: {
    // 判断是否消费过的
    isConsumed: (state) => {
      return state.userInfo?.pay_count > 0
    },
    userRegisterCountry: (state) => {
      return state.userInfo.register_country
    },
    isLogin: (state) => {
      // 判断是否登录了
      return state.userInfo && Object.keys(state.userInfo).length
    },
    // 判断老订阅是否订阅过
    isSub: (state) => {
      return state.subscription_info.status !== SubStatus.NoSub
    },
    // 判断新老订阅是否正常订阅
    isNormalSub: (state) => {
      return (
        state.subscription_info.status === SubStatus.Normal || state.subscription_info_v2.status === SubStatus.Normal
      )
    },
    // 判断新订阅是否订阅过
    isRealSub: (state) => {
      return state.subscription_info_v2.status !== SubStatus.NoSub
    },
    // 支付状态
    paymentStatus: (state) => {
      return state.subscription_info.payment_status
    },
    // 用户昵称
    nickname: (state) => {
      const { first_name, last_name, name } = state.userInfo
      if (first_name) {
        return `${first_name}${last_name}`
      }
      return name
    },
    // 用户email
    userEmail: (state) => {
      return state.userInfo.email
    },
    // 用户昵称首字母
    nicknameLetter: (state) => {
      return state.userInfo?.name ? state.userInfo?.name?.slice(0, 1) : state.userInfo?.email?.slice(0, 1)
    },
    productType: (state) => {
      return state.userInfo.product_type
    },
    // 是否是商家
    isBusinessUser: (state) => {
      return state.userInfo.role_id === LowId.BusinessUser
    },
    // 是否是子用户
    isChildUser: (state) => {
      return state.userInfo.role_id === LowId.ChildUser
    },
    // 是否是普通用户
    isNormalUser: (state) => {
      return state.userInfo.role_id === LowId.NormalUser
    },
    // 子管理员用户
    isChildBusinessUser: (state) => {
      return state.userInfo.role_id === LowId.ChildBusinessUser
    },
    isChildUserAndBusinessUser: (state) => {
      return state.userInfo.role_id === LowId.ChildBusinessUser || state.userInfo.role_id === LowId.ChildUser
    },
    // 是否绑定了手机号
    isBindPhone: (state) => {
      return !!(state.userInfo.phone?.length && state.userInfo.phone_cc?.length)
    },
    // 是否绑定了email
    isBindEmail: (state) => {
      return !!(state.userInfo.email?.length && state.userInfo.email_verified)
    },
    // 是否待校验email
    isVerifyEmail: (state) => {
      return !!(state.userInfo.email?.length && !state.userInfo.email_verified)
    },
    // 上级商家名字
    getTopBusinessName: (state) => {
      return state.userInfo.admin_name
    },
    // 是否是拉黑用户
    isBadUser: (state) => {
      return state.userInfo.is_bad
    },
    // 获取用户手机号，eg:+8613665092299
    getFullPhoneCode: (state) => {
      return `+${state.userInfo.phone_cc}${state.userInfo.phone}`
    },
    // 用户设置的语言
    userLanguage: (state) => {
      return state.userInfo.language
    }
  }
})
