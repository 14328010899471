import { ComboApi } from "@/constant/api/combo"
import http from "@/http/index"
import { ErrorTipType } from "@/constant/pages/landingCombo/index"
import { IPaymentLocalValue } from "@/composables/pages/combo/index"
import http2 from "~~/http/http2"
import { ISubPaymentObj } from "@/composables/api/profile"

export enum IActivityTypeName {
  BlackFriday = "BlackFriday",
  CyberMonday = "CyberMonday"
}

export interface IComboProductListObj {
  currency_char: string
  id: number
  name: string
  paddle_id: number
  per_credit_price: number
  discount_percentage: number | string
  total_price: number
  credits: number
  most_popular: boolean
  origin_price: number
  top: boolean
  time_limited: boolean
  buy_now: boolean
  complimentary_credits?: number
  extra_credits?: number
  currency?: any
  currency_char_prefix?: any
  category: ILandingCategoryType
}

export enum INewCompareState {
  NoSub = 0,
  CurrentSub,
  Upgrade,
  DownUpgrade
}

export interface INewComboProductListObj {
  category?: ILandingCategoryType
  compare_state?: INewCompareState // 0:未订阅|1:当前订阅|2:升级|3:降级
  credits: number
  currency: string
  currency_char: string
  currency_char_prefix: boolean
  device_limit: number // 设备数限制
  discount_per_credit_price: string
  discount_price: string // 优惠后的总价格 未空不优惠
  discount_percentage: number // 优惠了百分比
  discount_amount: string // 优惠的价格
  duration_seconds: number
  id: number
  name: string
  per_credit_price: string
  price: string
  period_start: string
  period_end: string
}

export enum IAvaiableTradeEnum {
  Refunding = -2,
  Refunded = -3
}

export type IAvaiableTrade = {
  expired_at: string
  left_count: number
  name: string
  product_id: number
  total_count: number
  trade_no: string
  type: IBasicInfoSubComboType
  created_at: string
  state: IAvaiableTradeEnum
}[]

export interface INewMyBillingData {
  subscription_info: {
    avaiable_trade?: IAvaiableTrade
    card_tail?: string
    expires?: string
    invalid_trade?: IAvaiableTrade
    next_payment?: string
    next_plan_name?: string
    payment_method?: 1
    payment_platform?: 1
    plan_name?: string
    status?: SubStatus
    is_v2?: boolean
    payment_updated_url?: string
    total_count?: number
    left_count?: number
    next_got_credits?: number
    next_plan_credits?: number
    sub_left_count?: number //当前订阅以及roll up的剩余总张数
    plan_id?: number // 当前订阅id
    is_max_plan?: boolean //记录订阅是否是最大订阅
  }
  payment: ISubPaymentObj[]
}

export type IINewComboProductListObjData = INewComboProductListObj[]

export type IComboProduct = IComboProductListObj[]

// 夏促的落地页状态
export enum IPromotionComboState {
  NoStart = 0,
  Inprogress,
  IsEnd
}

export enum ILandingProductType {
  OldComboPks = 0,
  NewComboSub
}

export enum ILandingCategoryType {
  OldSub = 0,
  OldComboPks,
  NewComboSub,
  PayAsYouGo,
  TopUp
}

export type ILandingComboProduct = {
  subscription: boolean
  paddle_enable: boolean
  product_type: ILandingProductType
  payssion_enable: boolean
  pm_config: string
  product: IComboProductListObj | IComboProduct | IINewComboProductListObjData
  tag: ErrorTipType
  copywriter: string
  title_copywriter?: string
  buy_count?: number
  timestamp?: number
  cover_image?: string
  video_link?: string
  time_state?: IPromotionComboState
  start_timestamp?: number
  product_additional?: IINewComboProductListObjData
  current_plan?: IComboProductListObj | IComboProduct | IINewComboProductListObjData
}

export interface IComboProductList {
  product: IComboProduct
  subscription: boolean
  pm_config: string
  price_range: string[]
}

export enum IComboProductByMyAvailableState {
  REFUNDED = -3,
  REFUNDING = -2,
  REFUNDING2 = -5,
  CHARGEBACK = -4
}

export type IComboProductByMyAvailable = {
  expired_date: string
  name: string
  total_credits: number
  used_count: number
  state?: IComboProductByMyAvailableState
}[]

export type IComboProductByMyPayment = {
  amount: number
  billing_date: string
  id: string
  receipt_url: string
}[]

export interface IComboProductByMy {
  available: IComboProductByMyAvailable
  payment: IComboProductByMyPayment
  remaining: number
  subscription: boolean
  total: number
  unavailable: IComboProductByMyAvailable
}

export enum ITradeState {
  Pending = 0,
  Success = 1,
  Close = 2
}

export interface IViteQrQueryParams {
  trade_no: string
  transaction_id?: string
}

export interface IViteQrDiscountParams {
  product_id: number
  coupon_code: string
  platform_id?: number
  custom_link_uuid?: any
}

export enum IUserPaymentType {
  PaddleCard = 1,
  LocaleCard
}

export enum IUserPaymentPlatform {
  Paddle = 1,
  Passion = 2,
  AirWallex = 4,
  Nuvei = 6
}

export interface IUserPaymentMethodObj {
  id?: number
  key?: string
  platform?: number | IUserPaymentPlatform
  type?: IUserPaymentType
  is_saved_card?: boolean
  card_tail?: string
  card_expires?: string
  [x: string]: any
}

export type IAirwallexCreate = {
  client_secret: string
  intent_id: string
  required_contact_field: string[]
  trade_no: string
  currency: string
  checkout_url?: string
  session_token?: string
}

export type IUserPaymentMethodAll = (IUserPaymentMethodObj & { [x: string]: any })[]

// 获取套餐列表
export const getComboProductList = () => {
  return http2.get<IComboProductList>(ComboApi.GET_PRODUCT_LIST)
}

// 获取我的套餐
export const getComboProductByMy = () => {
  return http.get<IComboProductByMy>(ComboApi.GET_PRODUCT_MY)
}

// 获取viteqr扫码链接
export const postViteQrLink = (data: {
  product_id: number
  coupon_code?: string
  pm_id?: string
  uuid?: string
  source?: any
  return_url?: string
  is_sec_kill_activity?: boolean
  gift_card_receiver?: string
  sender_name?: string
  purchase_uuid?: string
  purchase_source?: string
  ads_conversion?: string
}) => {
  return http.post<{ redirect_url: string; trade_no: string }>(ComboApi.POST_VITEQR_LINK, {
    ...data,
    ...useFprCookie()
  })
}

// 获取viteqr扫码链接 无token版本
export const postViteQrLinkNoToken = (data: {
  product_id: number
  coupon_code?: string
  pm_id?: string
  uuid?: string
  source?: any
  return_url?: string
  gift_card_receiver?: string
  sender_name?: string
  purchase_uuid?: string
  purchase_source?: string
  ads_conversion?: string
}) => {
  return http.post<{ redirect_url: string; trade_no: string }>(ComboApi.POST_VITEQR_CUSTOM_LINK, data)
}

// 查询订单状态
export const postViteQrQuery = (data: IViteQrQueryParams) => {
  return http.post<{
    trade_state: ITradeState
    product_name?: string
    amount?: number
    credits?: number
    currency?: string
  }>(ComboApi.POST_TRADE_QUERY, data)
}

// 获取viteqr支付优惠信息
export const postPaymentPolyDiscount = (data: IViteQrDiscountParams) => {
  return http.post<{ amount: number; currency_char: string; discount: number; message?: string }>(
    ComboApi.GET_DISCOUNT_V2,
    data
  )
}

// 获取落地页套餐数据
export const getLandingComboProduct = (uuid: string) => {
  return http2.get<ILandingComboProduct>(ComboApi.GET_LANDING_COMBO, { uuid })
}

// 获取下载落地页套餐数据
export const getLandingDownloadComboProducts = () => {
  return http.get<ILandingComboProduct>(ComboApi.GET_DOWNLOAD_COMBO)
}

// 保存当前套餐页面到user
export const PostSavePricingTypeToUser = (pricing_type: IPaymentLocalValue) => {
  return http.post<any>(ComboApi.GET_USER_PAYMENT_METHOD, { pricing_type })
}

// 获取用户的支付方式（paddle、passion、airwallex）
export const postPaymentMethodAll = (
  options: { custom_link_uuid?: string; alink_uuid?: string; product_id?: number } = {}
) => {
  return http.post<IUserPaymentMethodAll>(ComboApi.GET_USER_PAYMENT_METHOD, options)
}

// 查询airwallex订单信息
export const postPaymentPolyQuery = (options: { trade_no: string; transaction_id?: string }) => {
  return http.post<{
    trade_state: ITradeState
    product_name?: string
    amount?: number
    credits?: number
    currency?: string
  }>(ComboApi.POST_AIRWALLEX_PAYMENT_QUERY, options)
}

// 创建订单信息
export const postPaymentPolyCreate = (options: { method_id: number; platform_id: number; product_id: number }) => {
  return http.post<IAirwallexCreate>(ComboApi.POST_AIRWALLEX_PAYMENT_CREATE, { ...options, ...useFprCookie() })
}

// 新订阅-获取所有订阅计划
export const getNewAllSubComboData = () => {
  return http2.get<{ data: IINewComboProductListObjData; meta: { time_left: number } }>(
    ComboApi.GET_NEW_PRODUCT_SUB_LIST_V2,
    {},
    {},
    true
  )
}

// 新订阅-获取所有订阅的补充包或者pay as you go
export const getNewAllSubPacksData = () => {
  return http2.get<IINewComboProductListObjData>(ComboApi.GET_NEW_PRODUCT_SUB_LIST_OTHER_LIST)
}

// 获取个人中心我的账单
export const getNewMybillingData = () => {
  return http2.get<INewMyBillingData>(ComboApi.GET_NEW_SUB_MY_INFO)
}

// 直接购买补充包
export const postNewSubBuyTopUp = (options: { product_id: number; custom_link_uuid?: string; [x: string]: any }) => {
  return http.post<{ trade_no: string }>(ComboApi.POST_NEW_SUB_BUY_TOP_UP, options)
}

// 新版订阅升降级
export const postNewSubUpdate = (options: { product_id: number; custom_link_uuid?: string; [x: string]: any }) => {
  return http.post<{ trade_no: string }>(ComboApi.POST_NEW_SUB_UPDATE, options)
}

// 新版恢复订阅- 撤销取消订阅，reactived
export const postNewSubRecover = (options?: { [x: string]: any }) => {
  return http.post<{ trade_no: string }>(ComboApi.POST_NEW_SUB_RECOVER, options)
}

// 新版取消订阅
export const postNewSubCancel = (args: { recover?: boolean; reason_id?: number[]; reason_content?: string }) => {
  return http.post<{ trade_no: string }>(ComboApi.POST_NEW_SUB_CANCEL, args)
}

// 获取活动内容 -》 万圣节？黑五
export const getAllActivity = () => {
  return http2.get<{ is_discount: boolean; time_left: number; name?: IActivityTypeName }>(
    ComboApi.GET_DISCOUNT_ACTIVITY
  )
}

// 获取活动内容 -》 万圣节？黑五
export const postBlackFridaysEmail = (product_id: number) => {
  return http2.post<any>(ComboApi.POST_BLACK_FRIDAYS_EMAIL, {
    product_id
  })
}

// 删除用户卡信息
export const postDeleteCardInfo = () => {
  return http2.post<any>(ComboApi.DELETE_CARD_INFO)
}
