import { commonApi, InterfaceStateEnum } from "@/constant/api/common"
import http from "@/http/index"
import http2 from "@/http/http2"
import { IReferrerParams } from "@/constant/referrer"
import { IPaymentLocalValue } from "@/composables/pages/combo/index"
import { ILocaleValue } from "@/constant/i18n-country"
import { IChannelCodeTag } from "@/constant/referrer"
import { LOGIN_SCENE, VERIFY_TYPE } from "@/constant/login"
import { scopeEnum } from "~~/constant/diversion"
import { INewMyBillingData } from "@/composables/api/combo"

export enum ThanksDialogTextType {
  Type1 = 1, // 下载弹窗后的
  Type2, // 帮助页面提交后的
  Type3 // contact us提交后的
}

export enum GroupLinkType {
  LINE = 1, // 日本
  FB1 = 2, // 越南
  FB2 = 3, // 其他
  KAKAO = 4, // 韩国
  TWITTER = 5, // 推特
  ZALO = 6 //  zalo 越南
}

export const PointGroupButtonName = {
  [GroupLinkType.LINE]: "joinLine",
  [GroupLinkType.FB1]: "joinFBViet",
  [GroupLinkType.FB2]: "joinFBNational",
  [GroupLinkType.KAKAO]: "joinKakao",
  [GroupLinkType.TWITTER]: "joinTwitter",
  [GroupLinkType.ZALO]: "joinZalo"
}

export enum GroupLinkSuccessType {
  LINE = 1,
  ZALO = 2,
  WHAT = 3,
  TWITTER = 4
}

interface ICheckTokenData {
  token?: string
  lfts: number
  rlfts: number
}

export interface ISetStateLogin {
  info: {
    user_info?: ITokenInfo["user_info"]
    [x: string]: any
  }
  token: string
  user_id: unknown
}

interface ILoginInfo {
  has_check_terms: number
  last_login: number
  login_tag: number
  exist: boolean
  mfa: number
}

interface IStateLoginBody {
  email?: string
  phone?: string
  phone_cc?: string
  verify_code?: string
  uuid?: string | number
  password?: string
  minfo?: string
  jump_token?: string
  from_web?: number
  no_passwd?: 1 | undefined
  recover_token?: string // 恢复账号code
  mfa_verify_code?: string // 二次认证code
  agree_mail_sub?: any
  t?: string
  channel?: string
  channel_code?: string
  edm_email?: string
  edm_verify_code?: string
  landing_uuid?: string
  ads_conversion?: {
    fb_click_id?: string
    google_click_id?: string
    twitter_click_id?: string
    keyword?: string
    ad_id?: string
    group_id?: string
    campaign_id?: string
    source?: string
  }
  name?: string
  scene?: LOGIN_SCENE
  mfa_password?: boolean
}

export type IRegistryBody = Pick<IStateLoginBody, "email" | "verify_code" | "password" | "minfo"> & {
  channel?: IReferrerParams
  inviter?: string
  landing_uuid?: string
  t?: any
  channel_code?: string
  agree_mail_sub?: number
  phone?: {
    countryValue: string
    inputValue: string
  }
  user_email?: string
}

export type IScene = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 10 | 11 | 12

interface ISendCodeBody {
  t?: string
  phone_cc?: string
  phone?: string
  email?: string
  user_email?: string
  scene?: IScene //  1-注册 2-登录 3-修改密码 4-忘记密码 5-联系我们 6-网红合作 7-落地页  10-验证码登录 12-辅助邮箱验证码
}

type ICheckCodeBody = ISendCodeBody & { code?: string }

interface IOauthUrl {
  url: string
  uuid: string
}

interface IOauthUrlParams {
  type: number | string
  lang?: ILocaleValue
}

interface IOauthAtt {
  code: string
  uuid: string
  state: string
  inviter?: string
  channel?: IReferrerParams
  landing_uuid?: string
  agree_mail_sub?: number
}

interface IOauthAttData {
  user_exist: boolean
}

interface IOauthUuidData {
  code: string
  email: string
  state: number
  has_check_terms: number
  uuid: string
  need_verify_email: boolean
  user_exist: boolean
}

// 1 正常 4 3过期或者支付失败
export enum SubStatus {
  NoSub = 0, //没订阅过
  AwaitCancelled = 3, // 等待取消订阅
  AwaitDownGrade = 2, // 等待取消降级
  Normal = 1, // 正常
  Failed = 4, // 支付失败
  Failed3 = 5, // 手动取消
  Failed4 = 6 // paddle取消
}

export enum PaymentStatus {
  Read = 0,
  Loading = 1,
  Success = 2,
  Failed = 3
}

export enum ProductType {
  Sub = 1, // 订阅
  VN_PKG = 2, // 越南套餐
  MY_PKG = 3, // 马来西亚套餐
  TH_PKG = 4, // 泰国套餐
  PH_PKG = 5, // 菲律宾套餐
  ID_PKG = 6, // 印度尼西亚套餐
  JP_PKG = 7, // 日本套餐
  OTHER = 8, // 其他基础套餐
  KR_PKG = 10, //韩元套餐
  EU_PKG = 12, // 欧元套餐
  GB_PKG = 13, // 英国定价
  AU_PKG = 14, // 澳元
  TW_PKG = 15, // 台币
  HK_PKG = 16 // 港币
}

// 展示套餐的国家
export const getComboTypeList = () => {
  return Object.keys(ProductType)
    .filter((_) => !isNaN(Number(_)))
    .filter((_) => Number(_) !== ProductType.Sub)
    .map((_) => Number(_))
}

// 商家分类 0为普通用户 1为子用户 2为商家用户
export enum LowId {
  NormalUser = 0,
  ChildUser = 1,
  BusinessUser = 2,
  ChildBusinessUser = 3
}

export enum IUserMailSubType {
  Type1 = 1,
  Type2,
  Type3,
  Type4
}

export enum IBasicInfoScope {
  preset_count = "preset_count",
  basic = "basic",
  trade = "trade"
}

export enum IBasicInfoSubComboType {
  NormalCombo = 0,
  NormalSubCombo,
  SubUpgradeRemainingCombo,
  TopupCraditCombo,
  RollupCombo
}

export interface ITokenInfo {
  preset_count?: number
  login_tag?: number
  has_qualification?: boolean
  user_info: {
    phone_cc?: string
    phone?: string
    admin_name?: string
    id?: number | string
    uuid?: string
    name?: string
    org_id?: number | string
    email?: string
    is_vip?: number
    avatar?: string
    first_login_app_time?: string
    product_type?: ProductType
    pricing_type?: IPaymentLocalValue // 判断进入哪个套餐页面
    role_id?: LowId
    register_country?: any
    is_bad: boolean
    sv2: boolean
    language: string
    timezone: string
    email_verified: boolean
    verified_methods?: number
    recover_email?: string
    first_name?: string
    last_name?: string
    [x: string]: any
  }
  subscription_info: {
    downgraded_plan_name?: string
    status?: SubStatus
    payment_status?: PaymentStatus
    name?: string
    payment_type?: 1 | 2 // monthly = 1  yearly = 2
    is_canceled?: boolean
    is_downgraded?: boolean
    is_past_due?: boolean
    is_subscribed?: boolean
    total_credits?: number
    used_credits?: number
    next_bill_date?: string
    next_get_credits?: number
    update_payment_url?: string
    [x: string]: any
  }
  subscription_info_v2?: Pick<
    INewMyBillingData["subscription_info"],
    | "is_v2"
    | "status"
    | "next_plan_name"
    | "next_payment"
    | "payment_updated_url"
    | "sub_left_count"
    | "plan_name"
    | "card_tail"
    | "expires"
    | "plan_id"
    | "is_max_plan"
    | "left_count"
    | "total_count"
    | "next_plan_credits"
  >
  social_list: { platform: number; nick_name: string; [x: string]: any }[]
  admin_name?: string
  mail_sub_type: IUserMailSubType[]
}

export interface IEnumListObj {
  id: string | number
  type: string | number
  name: string
  sort: number
  sub_type?: number
}

export type IEnumListData = IEnumListObj[]

export interface IConsultationAddObj {
  first_name: string
  last_name: string
  contact_type: any
  business_email: string
  phone_country_code: string
  phone: string
  company_name: string
  country_id: number
  monthly_phone_count_id: number
  content: string
}

export type CheckInterfaceStateParam = {
  scene: number
  url: string
}

export interface IBindUserPhoneOrEmailOptions {
  email?: string
  phone?: string
  phone_cc?: string
  email_code?: string
  phone_code?: string
  bind_type: VERIFY_TYPE //1:email|2:phone
}

export interface IUBindUserPhoneOrEmailOptions {
  email?: string
  phone?: string
  phone_cc?: string
  code?: string
  unbind_type: VERIFY_TYPE //1:email|2:phone
}

export enum IPolicyKeyEnum {
  termsOfUseUrl = "termsOfUseUrl",
  privatePolicyUrl = "privatePolicyUrl"
}

// 登录接口
export const setStateLoginApi = <T = ISetStateLogin>(body: IStateLoginBody) => {
  return http.post<T>(commonApi.LOGIN_API, Object.assign({ from_web: 1, ...useFprCookie() }, body))
}

// 获取登录信息 判断是否注册
export const getLoginInfoApi = <T = ILoginInfo>(params: { email?: string; phone?: string; phone_cc?: string }) => {
  return http.get<T>(commonApi.GET_LOGIN_INFO, params)
}

// 忘记密码
export const forgetPsdApi = <T = any>(
  body: Pick<IStateLoginBody, "email" | "verify_code" | "password" | "phone_cc" | "phone">
) => {
  return http.post<T>(commonApi.FORGET_PASSWORD, body)
}

// 修改密码
export const changePsdApi = <T = any>(body: IStateLoginBody) => {
  return http.post<T>(commonApi.CHANGE_PASSWORD, body)
}

// 修改密码
export const postRegPsdApi = <T = any>(body: Pick<IStateLoginBody, "password">) => {
  return http.post<T>(commonApi.REF_PSD, body)
}

// 注册接口
export const registryUserApi = <T = ISetStateLogin>(body: IRegistryBody) => {
  return http.post<T>(
    commonApi.REGISTRY_API,
    Object.assign(
      { from_web: 1, channel: useReferrer2().value || useReferrer().value || undefined },
      { ...body, ...useFprCookie() }
    )
  )
}

// 判断注册接口是否需要人机验证
export const postNc = <T = { n: boolean }>(body: { uuid?: any }) => {
  return http.post<T>(commonApi.POST_NC, body)
}

// 判断发送手机验证码之前是否需要人机
export const postVc = <T = { n: boolean }>(body: { phone_cc: string; phone: string }) => {
  return http.post<T>(commonApi.POST_VC, body)
}

// 发送验证码
export const sendVerCodeAPi = <T = ISetStateLogin>(body: ISendCodeBody) => {
  return http.post<T>(commonApi.SEND_VERIFY_CODE, body)
}

// 校验验证码
export const checkVerCodeApi = <T = { code: string }>(body: ICheckCodeBody) => {
  return http.post<T>(commonApi.CHECK_VERIFY_CODE, body)
}

// 获取第三方跳转链接
export const getOauthUrlApi = <T = IOauthUrl>(params: IOauthUrlParams) => {
  return http.get<T>(commonApi.GET_OAUTH_URL, Object.assign({ scene: 2, lang: useI18nCountry1().value }, params))
}

// oauth认证
export const oauthApi = <T = IOauthAttData>(body: IOauthAtt) => {
  return http.post<T>(
    commonApi.OAUTH_URL,
    Object.assign(
      { scene: 2, channel: useReferrer2().value || useReferrer().value || undefined, ...useFprCookie() },
      body
    )
  )
}

// oauth获取uuid
export const getOauthUuidApi = <T = IOauthUuidData>(params: { uuid: string }) => {
  return http.get<T>(commonApi.GET_OAUTH_STATE, params)
}

// 获取用户详细信息
export const getBasicInfoApi = <T = ITokenInfo>(params: { simple?: number; scope?: IBasicInfoScope }) => {
  return http2.get<T>(commonApi.GET_BASIC_INFO_V2, params)
}

// 第三方登录解绑
export const postSocialDisconnect = <T = any>(body: { platform: number }) => {
  return http.post<T>(commonApi.SOCIAL_DISCONNECT, body)
}

// 枚举接口
export const getEnumList = <T = IEnumListData>(type: number) => {
  return http.get<T>(commonApi.GET_ENUM_LIST, { type })
}

// 提交咨询表单
export const postConsultationAdd = (args: IConsultationAddObj) => {
  return http.post(commonApi.CONSULTATION_ADD, args)
}

// 修改名字
export const postModifyName = (args: { first_name: string; last_name: string }) => {
  return http.post(commonApi.MODIFY_NAME, args)
}

// 获取国家地址 ->翻译
export const getClientCountry = <T = string>() => {
  return http.get<T>(commonApi.GET_CLIENT_COUNTRY)
}

// 检查token状态
export const postCheckToken = <T = ICheckTokenData>() => {
  return http.post<T>(commonApi.CHECK_TOKEN)
}

// 刷新token
export const postRefreshToken = <T = ICheckTokenData>() => {
  return http.post<T>(commonApi.REFRESH_TOKEN)
}

// 获取fb群组
export const getFbGroup = () => {
  return http.get<string>(commonApi.GET_FB_GROUP_URL)
}

export const checkInterfaceState = (data: CheckInterfaceStateParam) => {
  return http.post<InterfaceStateEnum>(commonApi.CHECK_INTERFACE_STATE, data)
}

// 获取国家ip
export const getCountryIp = () => {
  return http.get<{ ip: string; user_country: string }>(commonApi.GET_COUNTRY_IP)
}

// 获取联系我们群组
export const getContactGroupUrl = (email?: string, channel_uuid?: string) => {
  return http.get<{ type: GroupLinkType; url: string }>(commonApi.GET_CONTACT_GROUP_URL, { email, channel_uuid })
}

// 获取联系我们群组v2
export const getContactGroupUrlV2 = (label: scopeEnum, email?: string, channel_uuid?: string) => {
  return http.get<{ style: GroupLinkType; addr: string }>(commonApi.GET_CONTACT_GROUP_URL_V2, {
    email,
    channel_uuid,
    label
  })
}

// 获取支付成功 加入技术支持二维码
export const getContactCodeErweima = (uuid?: any) => {
  return http.get<{ code_url: { type: GroupLinkSuccessType; url: string; email: string } }>(
    commonApi.GET_CONTACT_CODE,
    { uuid }
  )
}

// 获取jump_token 的uuid
export const getJumpToken = <T = { jump_token: string }>(scene?: number) => {
  return http.get<T>(commonApi.GET_JUMP_TOKEN, {
    scene
  })
}

// 根据uuid获取渠道名称
export const getOrgChannelByUuid = (uuid: string) => {
  return http.get<{ first_name: string; second_name: string }>(commonApi.GET_CHANNEL_NAME, { uuid })
}

// 获取通用表格数据 post
export async function getTableData(url: string, params: Record<string, any> = {}) {
  const reusltParams: Record<string, unknown> = {}
  for (const key in params) {
    if (params[key]) {
      reusltParams[key] = params[key]
    }
  }
  return http.post(url, reusltParams, {}, true)
}

export enum IDecoratorType {
  Type1 = 1,
  Type2 = 2
}

export enum IDecoratorButtonType {
  Type1 = 1,
  Type2,
  Type3,
  Type4
}

// 获取公告接口
export const getNoticeValues = () => {
  return http.get<{
    state: boolean
    content: string
    btn_style: IDecoratorButtonType
    btn_text: string
    btn_url: string
    element: IDecoratorType
    icon: string
  }>(commonApi.GET_ANNNOUNCEMENT)
}

// 传送 formdata 格式不需要headers的content-type
export const postOpenHairApi = (args: FormData) => {
  return http.post(process.env.Upload_Image_Url, args, {
    headers: {}
  })
}
// 去背景API
export const backgroundRemovalApi = async (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=1", args, {
    headers
  })
}
// 换发色API
export const changeHairColorApi = (args: FormData, headers?: object, sub_type = 1) => {
  return http.post(process.env.Feature_Image_Upload + `?preview_type=2&sub_type=${sub_type}`, args, {
    headers
  })
}
// 眼镜去反光API
export const glareRemovalApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=4", args, {
    headers
  })
}

// blemishRemoval API
export const blemishRemovalApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=3", args, {
    headers
  })
}
// 证件照优化API
export const headShotApi = async (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=5", args, {
    headers
  })
}
// 加微笑API
export const smileGenerationApi = async (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=6", args, {
    headers
  })
}
// 图片裁剪API(纯加水印)
export const imageCropperApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=7", args, {
    headers
  })
}
// 获取im和国家语言
export const getLanguageAndIm = <
  T = {
    language: ILocaleValue
    group_id: number
    is_open_im: boolean
    is_open_channel_code: boolean
    is_v2_subscription: boolean
    ip_language: ILocaleValue
    [x: string]: any
  }
>() => {
  return http2.get<T>(commonApi.GET_LANGUAGE_IM)
}

// 设置channel_code
export const postSetChannelCode = (channel_code: string) => {
  return http.post<{ tag: IChannelCodeTag }>(commonApi.SET_CHANNEL_CODE, { channel_code })
}

// 设置邮箱通知checkbox
export const postUpdateMailSub = (mail_sub_type: IUserMailSubType[]) => {
  return http.post<any>(commonApi.UPDATE_MAIL_SUB, { mail_sub_type })
}

// 绑定手机号/邮箱
export const postBindEmailOrPhone = (options: IBindUserPhoneOrEmailOptions) => {
  return http.post<any>(commonApi.BIND_USER, options)
}

// 解绑手机号/邮箱
export const postUnbindEmailOrPhone = (options: IUBindUserPhoneOrEmailOptions) => {
  return http.post<any>(commonApi.UNBIND_USER, options)
}

// 更新edm邮箱
export const postUpdateEdm = (email: string) => {
  return http.post<any>(commonApi.UPDATE_EDM, { email })
}

export const postVerifyEdm = ({
  email,
  code,
  agree_mail_sub
}: {
  email: string
  code: string
  agree_mail_sub: boolean
}) => {
  return http.post<any>(commonApi.VERIFY_EDM, { email, code, agree_mail_sub })
}

export type IChangeEmailIdentifierData = {
  email: string
  bind_type: VERIFY_TYPE
  bind_code: string
  verify_type: VERIFY_TYPE
  verify_code: string
}

// 改变邮箱
export const postChangeIdentifierEmail = (data: IChangeEmailIdentifierData) => {
  return http.post<any>(commonApi.CHANGE_EMAIL, data)
}

// 获取live chat上报参数
export const getLiveChatParams = () => {
  return http.get(commonApi.GET_LIVE_CHAT)
}

// 测试发送埋点前的请求接口
export const getValidatePoint = () => {
  return http.get(commonApi.VALIDATE_POINT)
}

// 获取privacy协议
export const getApiPrivacy = (key: IPolicyKeyEnum) => {
  return http.get(commonApi.GET_PRIVACY, { key })
}

// 修改公司名字
export const postModifyCompanyName = (args: { name: string }) => {
  return http.post(commonApi.MODIFY_COMPANY_NAME, args)
}

// 修改时区、语言
export const postModifyLocal = (data: { language?: ILocaleValue; timezone?: string }) => {
  return http.post(commonApi.POST_SET_LOCAL, data)
}

// 开启二次认证
export const postOpenAuth = (data: { open: boolean }) => {
  return http.post(commonApi.POST_SET_AUTH_METHODS, data)
}
export const getAuthList = (data: { email?: string; phone?: string; phone_cc?: string }) => {
  return http.post(commonApi.POST_AUTH_LIST, data)
}

export const postBindRecover = (data: {
  recover_email: string
  verify_code: string
  scene?: LOGIN_SCENE
  email?: string
  phone?: string
  phone_cc?: string
}) => {
  return http.post(commonApi.POST_SET_RECOVER_EMAIL, data)
}
// 辅助邮箱发送邮件
export const sendEmail = (data: { email: string }) => {
  return http.post(commonApi.POST_SEND_EMAIL, data)
}
// code置换邮箱
export const getEmailByCode = (data: { code: string }) => {
  return http.post<{ email: string; code: string }>(commonApi.POST_CODE_EMAIL, data)
}
// 重置密码
export const resetEmail = (data: { password: string; code: string }) => {
  return http.post(commonApi.POST_RESET_PASSWORD, data)
}
export const postCheckRecover = (data: { email: string }) => {
  return http.post(commonApi.POST_CHECK_RECOVER_EMAIL, data)
}
// 绑定辅助邮箱发送验证码
export const sendRecoverVerCodeAPi = <T = ISetStateLogin>(body: ISendCodeBody) => {
  return http.post<T>(commonApi.POST_SEND_CODE, body)
}

export const postAuthByToken = (data: {
  access_token: string
  type: number
  scene: number
}): Promise<IOauthUuidData> => {
  return http.post(commonApi.POST_AUTH_TOKEN, data)
}

// 获取博客重定向数据
export const getBlogRedirectData = () => {
  const url = getCloudAssets("/redirect/redirect.json")
  return http2.get(
    url,
    {},
    {
      unAuthorization: true // 不携带token
    },
    true
  )
}
