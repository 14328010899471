// import Cookies from "js-cookie"
import { LocalStorageKey } from "~~/constant/cache"
import { APP_GTAG_MEASUREMENT_ID } from "~~/constant/config"
import { CookiesOptions } from "~~/constant/cookies"
import { useGtag, useState } from "vue-gtag-next"

// export enum GTagStatKey {
//   UserId = "_pix_ga_userId",
//   AppVersion = "_pix_ga_appVersion",
//   AppName = "_pix_ga_appName",
//   Platform = "_pix_ga_platform",
//   MeasurementId = "_pix_ga_merchantId"
// }
// 先从window里面取，取不到，再取cookie里面的值
// const UseCookieProperties = true
// const USE_H5_REPORT = true // 作为web上报
// const userPropertyKeys = [GTagStatKey.UserId, GTagStatKey.AppVersion, GTagStatKey.Platform, GTagStatKey.MeasurementId]

// const gaKeyMap = {
//   [GTagStatKey.UserId]: "user_id",
//   [GTagStatKey.AppVersion]: "app_version",
//   [GTagStatKey.Platform]: "platform",
//   [GTagStatKey.MeasurementId]: "merchant_id"
// }

interface PointQuery<T> {
  event_id: string
  data: T
}
export class TsGtagStat {
  static instance: TsGtagStat = null
  measurementId: string
  constructor() {
    this.measurementId = APP_GTAG_MEASUREMENT_ID
  }

  /**
   * 单例
   * @returns {TsStat}
   */
  static getInstance(): TsGtagStat {
    this.instance = this.instance ? this.instance : new TsGtagStat()
    return this.instance
  }

  changeEnabled(val: boolean) {
    const { isEnabled } = useState()
    if (isEnabled.value) {
      return
    }
    isEnabled.value = val
  }

  setEvent(name: string, params: any) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Analytics)
      if (!isEnabled) {
        return
      }

      const { event } = useGtag()
      event(name, { ...params, debug_mode: !(process.env.NUXT_APP_ENV === "production") })
    }
  }

  // 格式化数据，用于v-gtag-event事件
  formatPointData<T>(event_id: string, data: T): PointQuery<T> {
    return Object.freeze({ event_id, data })
  }
}
