import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "~~/constant/cookies"

class GtagAuPoint {
  static instance: GtagAuPoint = null
  static getInstance(): GtagAuPoint {
    this.instance = this.instance ? this.instance : new GtagAuPoint()
    return this.instance
  }
  setEvent(name: string, params: any) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }

      window?.gtag("event", name, params)
    }
  }
}

export default GtagAuPoint.getInstance()
