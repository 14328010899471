// 各feature页跳转下载携带参数
export const toDownloadQueryDict = {
  "/features/change-skin-color": "sId=naudfdyg&_uuid=gczqghbp",
  "/features/background-removal-service": "sId=dycbwkx2&_uuid=hqnkaunm",
  "/features/portrait-retouching": "_uuid=pnsdxsqf&sId=puqg7k3d",
  "/features/hair-color-changer": "_uuid=ikzsqbum&sId=vvvrnjud",
  "/features/photo-glare-remover": "_uuid=xd9uqfmd&sId=kxwxjbth",
  "/features/blemish-removal": "_uuid=pczrhtqw&sId=5ufprbga",
  "/features/headshot-photography": "_uuid=dlndyigx&sId=vb8oxm6y",
  "/features/change-smile-filter": "_uuid=yjqyvagt&sId=bjcb9m6z",
  "/features/ai-crop-image": "_uuid=zk4b5q6x&sId=93hwhnmk",
  "/features/batch-edits": "_uuid=jj2mjnsy&sId=sminku7q"
}
// feature页 key与url的映射
export const featurePageUrlMap = {
  changeSkinColor: "/features/change-skin-color",
  backgroundRemoval: "/features/background-removal-service",
  portraitRetouching: "/features/portrait-retouching",
  hairColorChanger: "/features/hair-color-changer",
  glareRemoval: "/features/photo-glare-remover",
  blemishRemoval: "/features/blemish-removal",
  headshot: "/features/headshot-photography",
  smileGeneration: "/features/change-smile-filter",
  imageCropper: "/features/ai-crop-image",
  batchEdits: "/features/batch-edits"
}
export enum IErrorType {
  unFormatCode = 3001, // 上传图片格式不符合
  noFaceCode = 3003
}

export enum showType {
  NONE = "none"
}
// feature Key
export enum featureType {
  IMAGE_CROPPER = "imageCropper"
}
