import { INavbarTheme } from "@/constant/navbar"
import { IActivityTypeName } from "@/composables/api/combo"
import { PAYMENT_COMBO_SUB, PAYMENT_PAGE_PATH, PAYMENT_COMBO_PROCEED_ROUTE } from "@/constant/route"
import { useOriginPath } from "~~/constant/useLocalPathConfig"

export const isPaymentOpen = (path) => {
  const { hallows_open } = useAllActivitys()
  return (
    hallows_open.value &&
    [
      useOriginPath(unref(PAYMENT_COMBO_SUB)),
      useOriginPath(unref(PAYMENT_PAGE_PATH)),
      useOriginPath(unref(PAYMENT_COMBO_PROCEED_ROUTE))
    ].includes(useOriginPath(path))
  )
}

export const blackSourceDict = {
  production: {
    sId: "fs2nvk8c",
    _uuid: "qga3gfpw"
  },
  development: {
    sId: "exrpvk8b",
    _uuid: "kpstpfo6"
  },
  pre: {
    sId: "exrpvk8b",
    _uuid: "kpstpfo6"
  }
}

export const appBlackSourceDict = {
  production: {
    sId: "2brg2jsc",
    _uuid: "rcytep9y"
  },
  development: {
    sId: "exrpvk8b",
    _uuid: "kpstpfo6"
  },
  pre: {
    sId: "exrpvk8b",
    _uuid: "kpstpfo6"
  }
}

export enum IActivityType {
  BlackFriday = 1,
  CyberFriday
}

export const activityTypeDict = {
  [IActivityTypeName.BlackFriday]: IActivityType.BlackFriday,
  [IActivityTypeName.CyberMonday]: IActivityType.CyberFriday
}

export const useHallowsOpen = () => useState("use_hallows_open", () => false)

export const useHallowsOpenTimeLeft = () => useState("use_hallows_open_time_left", () => 0)

export const useHallowsOpenType = () =>
  useState<IActivityType>("use_hallows_open_type", () => IActivityType.BlackFriday)

export const useAllActivitys = () => {
  const hallows_open = computed(() => {
    return useHallowsOpen().value
  })

  const resetHallowsOpen = () => {
    useHallowsOpen().value = false
  }

  const hallows_open_time_left = computed(() => {
    return useHallowsOpenTimeLeft().value
  })

  const hallows_open_type = computed<IActivityType>(() => {
    return useHallowsOpenType().value
  })

  const setNavbarTheme = (theme: INavbarTheme) => {
    if (theme === INavbarTheme.Dark) {
      useSetNavbarTheme(INavbarTheme.Dark)
      useFixedNavbarTheme().value = true
      useNavbarCustomBg().value = "#000"
    } else if (theme === INavbarTheme.White) {
      useSetNavbarTheme(INavbarTheme.White)
      useFixedNavbarTheme().value = false
      useNavbarCustomBg().value = "#000"
    }
  }

  const setHallowOpenValue = async () => {
    const hallowsOpen = useHallowsOpen()
    const hallowsOpenTimeLeft = useHallowsOpenTimeLeft()
    const hallowsOpenType = useHallowsOpenType()
    const { time_left, is_discount, name } = await getAllActivity()
    hallowsOpen.value = is_discount
    hallowsOpenType.value = activityTypeDict[name]
    hallowsOpenTimeLeft.value = time_left
  }

  return {
    hallows_open,
    hallows_open_time_left,
    hallows_open_type,
    isPaymentOpen,
    setNavbarTheme,
    setHallowOpenValue,
    resetHallowsOpen
  }
}

// 黑五动态navbar
export const useAllActivitysBlackNavbarTheme = () => {
  const { hallows_open, setNavbarTheme } = useAllActivitys()

  let windowScrollEvent = null

  function initScrollStyle() {
    windowScrollEvent = useEventListener(window, "scroll", function () {
      navBgChange()
    })
  }

  const navBgChange = () => {
    const top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    if (top <= 0) {
      useSetNavbarTheme(INavbarTheme.Dark)
      useFixedNavbarTheme().value = true
      useNavbarCustomBg().value = "transparent"
    } else {
      useSetNavbarTheme(INavbarTheme.Dark)
      useFixedNavbarTheme().value = false
      useNavBarBg().value = "#000"
    }
  }

  onMounted(async () => {
    if (hallows_open.value) {
      navBgChange()
      initScrollStyle()
    }
  })

  onBeforeUnmount(() => {
    windowScrollEvent && windowScrollEvent()
    setNavbarTheme(INavbarTheme.White)
    useFixedNavbarTheme().value = false
  })
}
