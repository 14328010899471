import { GOOGLE_CLOUD_URL } from "@/constant/config"
import { useOriginPath } from "~~/constant/useLocalPathConfig"
import tdk from "@/constant/tdk.json"
import {
  NEW_HOME_PAGE,
  PAYMENT_COMBO_SUB,
  PAYMENT_PAGE_PATH,
  CHANGE_SKIN_COLOR,
  IPAD_PAGE,
  BACKGROUND_REMOVAL,
  PORTRAIT_RETOUCHING,
  HAIR_COLOR_CHANGER,
  GLARE_REMOVAL,
  BLEMISH_REMOVAL,
  LANDING_VERSION,
  HEADSHOT,
  SMILE_GENERATION,
  CROP_IMAGE,
  BATCH_EDITS
} from "@/constant/route"
import { ILocaleValue } from "~/constant/i18n-country"

const canonicalMark = "canonical"

const featuresCanonicalMark = [
  
  CHANGE_SKIN_COLOR,
 
  BACKGROUND_REMOVAL,
 
  PORTRAIT_RETOUCHING,
 
  HAIR_COLOR_CHANGER,
 
  GLARE_REMOVAL,
 
  BLEMISH_REMOVAL,
  HEADSHOT,
  SMILE_GENERATION
,
  CROP_IMAGE
,
  BATCH_EDITS
]

export function useDefaultSEO(p?) {
  const path = useOriginPath(p || useRoute().path)
  const language = useI18nCountry()
  const tdkDict = tdk.dict
  const { metaDescription, titleTag } =
    tdkDict.find((item) => {
      const _p = new URL(item.route).pathname
      return _p === path && language.value === item.language
    }) || {}

  const i18nSeoHead = useLocaleHead({
    addSeoAttributes: true,
    addDirAttribute: true
  })
  if (path === useOriginPath(unref(NEW_HOME_PAGE))) {
    i18nSeoHead.value.link.find((l) => {
      if (l.rel === canonicalMark) {
        l.href = process.env.Website_Url + "/"
      }
    })
  }

  if (path === useOriginPath(unref(PAYMENT_COMBO_SUB))) {
    i18nSeoHead.value.link.find((l) => {
      if (l.rel === canonicalMark) {
        l.href = process.env.Website_Url + useOriginPath(unref(PAYMENT_PAGE_PATH))
      }
    })
  }

  featuresCanonicalMark.map((_) => {
    if (path === useOriginPath(unref(_))) {
      i18nSeoHead.value.link.find((l) => {
        if (l.rel === canonicalMark) {
          l.href = process.env.Website_Url + useOriginPath(unref(_))
        }
      })
    }
  })

  // 处理 es_Es 语言hreflang
  i18nSeoHead.value.link.find((l) => {
    if (l.hid === "i18n-alt-es_Es") {
      l.hreflang = "es-es"
    }
  })

  // 除英、日、韩外 canonical指向到英文的url
  if (
    path === useOriginPath(unref(IPAD_PAGE)) &&
    ![ILocaleValue.en, ILocaleValue.ja, ILocaleValue.ko].includes(language.value as ILocaleValue)
  ) {
    i18nSeoHead.value.link.some((l) => {
      if (l.rel === canonicalMark) {
        l.href = process.env.Website_Url + useOriginPath(unref(IPAD_PAGE))
        return true
      }
      return false
    })
  }

  // 除了中、英、日、韩外 canonical指向到英文的url
  if (
    path === useOriginPath(unref(LANDING_VERSION)) &&
    ![ILocaleValue["zh-Hant"], ILocaleValue.en, ILocaleValue.ja, ILocaleValue.ko].includes(
      language.value as ILocaleValue
    )
  ) {
    i18nSeoHead.value.link.some((l) => {
      if (l.rel === canonicalMark) {
        l.href = process.env.Website_Url + useOriginPath(unref(LANDING_VERSION))
        return true
      }
      return false
    })
  }

  // 补充推特meta信息
  const twitterDefaultMeta = [
    {
      property: "twitter:site",
      content: "Evoto"
    },
    {
      property: "twitter:title",
      content: titleTag
    },
    {
      property: "twitter:image",
      content: `${GOOGLE_CLOUD_URL}/images/footer-logo-1.png`
    },
    {
      name: "twitter:description",
      content:
        metaDescription ||
        `Evoto can enhance your workflow. Get properties listed faster with our high-quality professional AI photo editor. You can edit 1000 photos in two minutes.`
    }
  ]
  useHead({
    htmlAttrs: {
      ...i18nSeoHead.value.htmlAttrs
    },
    templateParams: {
      site: {
        name: "Evoto"
      },
      separator: "-"
    },
    title: titleTag || "EVOTO, AI-powered Photo Editor",
    // titleTemplate: "%s %separator EVOTO, AI-powered Photo Editor",
    titleTemplate: "%s",
    link: [...i18nSeoHead.value.link],
    meta: [
      {
        property: "og:site_name",
        content: "%site.name"
      },
      {
        name: "description",
        content:
          metaDescription ||
          `Evoto can enhance your workflow. Get properties listed faster with our high-quality professional AI photo editor. You can edit 1000 photos in two minutes.`
      },
      {
        property: "og:image",
        content: `${GOOGLE_CLOUD_URL}/images/footer-logo-1.png`
      },
      {
        property: "og:image:type",
        content: "image/png"
      },
      ...twitterDefaultMeta,
      ...i18nSeoHead.value.meta
    ]
  })
}
